/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-nested-ternary */
import {
	Checkbox,
	FormControlLabel,
	Paper,
	Switch,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	Typography,
} from '@mui/material';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSettingsRequest, postSettingsUpdateRequest } from 'redux/reducers/settings/reducer';
import { getSettingsIsLoad, getSettingsList } from 'redux/reducers/settings/selectors';
import { EditCellInput } from 'ui/EditCellInput';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionDetailsBlue,
	AccordionDetailsGreen,
	AccordionGray,
	AccordionSummary,
	AccordionSummaryGray,
} from 'ui/StyledAccordion';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import _ from 'lodash';
import { TSettingsData } from 'redux/reducers/settings/types';
import settingsJSON from './settings.json';
import { TYPE_OF_SETTINGS_RECIPIENTS, TYPE_OF_SETTINGS_SETTINGS } from './constants';
import { ISubmitVATReport, TTypeOfSettings } from './types';

export const SubmitVATReportList: FC = () => {
	const monthArray = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
	];
	const params = useParams<{ clientId: string }>();
	const { clientId } = params;

	const dispatch = useDispatch();
	const settings = settingsJSON as ISubmitVATReport;
	// const settings = useSelector(getSettingsList);

	const settingsIsLoad = useSelector(getSettingsIsLoad);

	const [typeOfSettings, setTypeOfSettings] = useState<TTypeOfSettings>(TYPE_OF_SETTINGS_SETTINGS);
	const [mainIndex, setMainIndex] = useState(0);
	const [settingsIndex, setSettingsIndex] = useState(0);
	const [recipientsIndex, setRecipientsIndex] = useState(0);
	const [recipientsSettingsIndex, setRecipientsSettingsIndex] = useState(0);

	const [cellValue, setCellValue] = useState<string | number | boolean>('');
	const [editCell, setEditCell] = useState(false);

	const [accordion1Expanded, setAccordion1Expanded] = useState<string | false>(false);
	const [accordion2Expanded, setAccordion2Expanded] = useState<string | false>(false);
	const [accordion3Expanded, setAccordion3Expanded] = useState<string | false>(false);

	const [editing, setEditing] = useState(false);
	// const [settings, setSettings] = useState(settingsJSON);

	const handleEditing = () => {
		setEditing(true);
	};
	const handleNotEditing = () => {
		setEditing(false);
	};

	useEffect(() => {
		dispatch(getSettingsRequest({ clientId: Number(clientId) }));
	}, [dispatch, clientId]);

	const handleEditValue = (
		value: string | number | boolean,
		indx: number,
		settingsIndx: number,
		typeSettings: TTypeOfSettings,
		recipientsIndx?: number,
		recipientsSettingsIndx?: number,
	) => {
		setCellValue(value);
		setMainIndex(indx);
		setSettingsIndex(settingsIndx);
		setEditCell(true);
		setTypeOfSettings(typeSettings);
		setRecipientsIndex(recipientsIndx ?? 0);
		setRecipientsSettingsIndex(recipientsSettingsIndx ?? 0);
	};

	const handleChangeCellValue = (value: string) => {
		setCellValue(value);
	};

	const handleCancelChangeCellValue = () => {
		setEditCell(false);
		setCellValue('');
	};
	// const handleDispatchChangeCellValue = () => {
	// 	handleCancelChangeCellValue();
	// 	const settingsTmp = _.cloneDeep(settings);
	// 	if (settingsTmp) {
	// 		if (typeOfSettings === TYPE_OF_SETTINGS_SETTINGS)
	// 			settingsTmp[mainIndex].settings[settingsIndex].value = cellValue;
	// 		else if (typeOfSettings === TYPE_OF_SETTINGS_RECIPIENTS)
	// 			settingsTmp[mainIndex].recipients[recipientsIndex].settings[recipientsSettingsIndex].value =
	// 				cellValue;
	// 		dispatch(postSettingsUpdateRequest({ clientId: Number(clientId ?? '0'), body: settingsTmp }));
	// 	}
	// };

	// const handleRecipientsListChange = (
	// 	settingsIndx: number,
	// 	recipientsIndx: number,
	// 	available: boolean,
	// ) => {
	// 	const settingsTmp = _.cloneDeep(settings);
	// 	if (settingsTmp) {
	// 		settingsTmp[settingsIndx].recipients[recipientsIndx].available = available;
	// 		// setSettings(settingsTmp);
	// 		dispatch(postSettingsUpdateRequest({ clientId: Number(clientId ?? '0'), body: settingsTmp }));
	// 	}
	// };

	const handleAccordion1Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion1Expanded(isExpanded ? panel : false);
			setAccordion2Expanded(false);
			setAccordion3Expanded(false);
			handleCancelChangeCellValue();
		};
	const handleAccordion2Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion2Expanded(isExpanded ? panel : false);
			setAccordion3Expanded(false);
			handleCancelChangeCellValue();
		};
	const handleAccordion3Change =
		(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
			setAccordion3Expanded(isExpanded ? panel : false);
			handleCancelChangeCellValue();
		};

	return (
		<>
			{!settings && !settingsIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No settings found...</p>
				</div>
			)}

			{settingsIsLoad && <Loader />}

			{!!settings && !settingsIsLoad && (
				<Paper sx={{ mt: '-24px', ml: '-24px', mr: '-24px' }}>
					<div>
						{settings.map((item, index) => (
							<AccordionGray
								expanded={accordion1Expanded === `panel${index}`}
								onChange={handleAccordion1Change(`panel${index}`)}
							>
								<AccordionSummaryGray
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1bh-content"
									id="panel1bh-header"
								>
									<Typography sx={{ width: '50%', flexShrink: 0 }}>{item.name}</Typography>
									<Typography sx={{ color: 'text.secondary' }} />
								</AccordionSummaryGray>
								<AccordionDetails>
									<div
										style={{
											borderLeft: '1px solid #ff840347',
											height: 'calc(100% - 48px)',
											position: 'absolute',
											marginTop: '-16px',
											marginLeft: '15px',
										}}
									/>
									{item.data.map((datum0Item, datum0Index) => (
										<Accordion
											expanded={accordion2Expanded === `panel2Settings-${datum0Index}`}
											onChange={handleAccordion2Change(`panel2Settings-${datum0Index}`)}
											sx={{ marginLeft: '20px' }}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1bh-content"
												id="panel1bh-header"
											>
												<Typography sx={{ width: '50%', flexShrink: 0 }}>
													{datum0Item.year} Quarter:{datum0Item.quarter}
												</Typography>
												<Typography sx={{ color: 'text.secondary' }}>
													Clients count: {datum0Item.clientsCount}
													{`, `}
													Unpaid amount: {datum0Item.unpaidAmount}
												</Typography>
											</AccordionSummary>
											<AccordionDetailsGreen>
												<div
													style={{
														borderLeft: '1px solid #88cf0385',
														height: 'calc(100% - 48px)',
														position: 'absolute',
														marginTop: '-16px',
														marginLeft: '15px',
													}}
												/>
												{datum0Item.data.map((datum1Item, datum1Index) => (
													<Accordion
														expanded={accordion3Expanded === `panel3Settings-${datum1Index}`}
														onChange={handleAccordion3Change(`panel3Settings-${datum1Index}`)}
														sx={{ marginLeft: '20px' }}
													>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon />}
															aria-controls="panel1bh-content"
															id="panel1bh-header"
														>
															<Typography
																sx={{
																	display: 'flex',
																	marginBottom: '10px',
																	// marginLeft: '20px',
																	width: '100%',
																}}
															>
																<Typography component="p" sx={{ width: '50%', flexShrink: 0 }}>
																	Client Name:{datum1Item.clientName}
																</Typography>
																<Typography
																	component="p"
																	sx={{ width: '50%', color: 'text.secondary' }}
																>
																	Total VAT amount: {datum1Item.totalVat}, Unpaid amount:{' '}
																	{datum1Item.unpaidAmount}
																</Typography>
															</Typography>
														</AccordionSummary>
														<AccordionDetailsBlue>
															<div
																style={{
																	borderLeft: '1px solid #08cdb869',
																	height: 'calc(100% - 48px)',
																	position: 'absolute',
																	marginTop: '-16px',
																	marginLeft: '15px',
																}}
															/>
															{datum1Item.data.map((datum2Item, datum2Index) => (
																<Typography
																	sx={{
																		display: 'flex',
																		marginBottom: '10px',
																		marginLeft: '50px',
																	}}
																>
																	<Typography component="p" sx={{ width: '50%', flexShrink: 0 }}>
																		{datum0Item.year} {monthArray[datum2Item.month]}{' '}
																	</Typography>
																	<Typography component="p" sx={{ color: 'text.secondary' }}>
																		Total VAT amount: {datum2Item.totalVat}, Unpaid amount:{' '}
																		{datum2Item.unpaidAmount}
																	</Typography>
																</Typography>
															))}
														</AccordionDetailsBlue>
													</Accordion>
												))}
											</AccordionDetailsGreen>
										</Accordion>
									))}{' '}
								</AccordionDetails>
							</AccordionGray>
						))}
					</div>
				</Paper>
			)}
		</>
	);
};
