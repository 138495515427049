import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@emotion/react';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/index.scss';

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript

const theme = createTheme({
	palette: {
		primary: {
			main: '#016193',
			contrastText: '#fff',
		},
		secondary: {
			main: '#7ea13a',
			contrastText: '#fff',
		},
		// success: {
		// 	main: '#7ea13a',
		// 	contrastText: '#fff',
		// },
		info: {
			main: '#6ec1e4',
		},
	},
});

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<App />
				<ToastContainer />
			</ThemeProvider>
		</PersistGate>
	</Provider>,
);
reportWebVitals();
