/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	TClientFilesRequestPayload,
	TClientFilesResponsePayload,
	TClientRequestPayload,
	TClientResponsePayload,
	TClientsRequestPayload,
	TClientsResponsePayload,
	TIntegrationTypesResponsePayload,
	TPostClientRequestPayload,
	TPostClientResponsePayload,
	TStartEasSendJobResponsePayload,
} from './types';
import {
	assetsInitState,
	deleteClientRequest,
	getClientFilesRequest,
	getClientFilesSuccess,
	getClientRequest,
	getClientsRequest,
	getClientsSuccess,
	getClientSuccess,
	getIntegrationTypesRequest,
	getIntegrationTypesSuccess,
	postClientRequest,
	postClientSuccess,
	startEasSendJobRequest,
	startEasSendJobSuccess,
} from './reducer';

function* getClientsWorker(action: PayloadAction<TClientsRequestPayload>) {
	const { payload } = action;

	try {
		const response: TClientsResponsePayload = yield call(api.clients.getClients, payload);
		yield put(getClientsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* postClientWorker(action: PayloadAction<TPostClientRequestPayload>) {
	const { payload } = action;
	try {
		const response: TPostClientResponsePayload = yield call(api.clients.postClient, payload);
		yield put(postClientSuccess(response));
		notificationContainer('client added successfully!', 'success');

		yield put(getClientsRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getClientWorker(action: PayloadAction<TClientRequestPayload>) {
	const { payload } = action;
	try {
		const response: TClientResponsePayload = yield call(api.clients.getClient, payload);
		yield put(getClientSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* deleteClientWorker(action: PayloadAction<TClientRequestPayload>) {
	const { payload } = action;
	try {
		yield call(api.clients.deleteClient, payload);
		yield put(getClientsRequest({}));
		notificationContainer('client successfully removed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getClientFilesWorker(action: PayloadAction<TClientFilesRequestPayload>) {
	const { payload } = action;
	try {
		const response: TClientFilesResponsePayload = yield call(api.clients.getClientFiles, payload);
		yield put(getClientFilesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getIntegrationTypesWorker() {
	try {
		const response: TIntegrationTypesResponsePayload = yield call(api.clients.getIntegrationTypes);
		yield put(getIntegrationTypesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* startEasSendJobWorker() {
	try {
		const response: TStartEasSendJobResponsePayload = yield call(api.clients.postStartEasSendJob);
		yield put(startEasSendJobSuccess(response));
		notificationContainer('Upload data for EAS successfully!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

export function* clientsSaga() {
	yield takeEvery(getClientsRequest.type, getClientsWorker);
	yield takeEvery(postClientRequest.type, postClientWorker);
	yield takeEvery(getClientRequest.type, getClientWorker);
	yield takeEvery(deleteClientRequest.type, deleteClientWorker);
	yield takeEvery(getClientFilesRequest.type, getClientFilesWorker);
	yield takeEvery(getIntegrationTypesRequest.type, getIntegrationTypesWorker);
	yield takeEvery(startEasSendJobRequest.type, startEasSendJobWorker);
}
