/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	settingsInitState,
	getSettingsRequest,
	getSettingsSuccess,
	postSettingsUpdateRequest,
	postSettingsUpdateSuccess,
} from './reducer';
import {
	TSettingsRequestPayload,
	TSettingsResponsePayload,
	TSettingsUpdateRequestPayload,
	TSettingsUpdateResponsePayload,
} from './types';

function* getSettingsWorker(action: PayloadAction<TSettingsRequestPayload>) {
	const { payload } = action;
	try {
		const response: TSettingsResponsePayload = yield call(api.settings.getSettings, payload);
		yield put(getSettingsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(settingsInitState());
	}
}

function* postSettingsUpdateWorker(action: PayloadAction<TSettingsUpdateRequestPayload>) {
	const { payload } = action;
	try {
		const response: TSettingsUpdateResponsePayload = yield call(api.settings.postSettings, payload);
		yield put(getSettingsRequest({ clientId: Number(payload.clientId) }));
		notificationContainer(String('Settings updated'), 'success');
		yield put(postSettingsUpdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		// yield put(settingsInitState());
		yield put(getSettingsRequest({ clientId: Number(payload.clientId) }));
	}
}

export function* settingsSaga() {
	yield takeEvery(getSettingsRequest.type, getSettingsWorker);
	yield takeEvery(postSettingsUpdateRequest.type, postSettingsUpdateWorker);
}
