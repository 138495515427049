import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TFilesExampleRequestPayload,
	TFilesExampleResponsePayload,
	TFilesFieldsResponsePayload,
	TFilesSeparatorsResponsePayload,
	TFilesStore,
	TMappingPresetsRequestPayload,
	TMappingPresetsResponsePayload,
	TPostMappingPresetsRequestPayload,
	TPostMappingPresetsResponsePayload,
	TPostFilesRequestPayload,
	TPostFilesResponsePayload,
	TStartUploadingRequestPayload,
	TDeleteMappingPresetsRequestPayload,
	TDeleteMappingPresetsResponsePayload,
	TDeleteFilesRequestPayload,
} from './types';
/* eslint-disable @typescript-eslint/no-unused-vars */
// ==========================================:
export const initialState: TFilesStore = {
	postFilesResponse: null,
	postFilesResponseLoader: false,
	deleteFilesResponseLoader: false,
	filesExample: null,
	filesExampleLoader: false,
	filesFields: null,
	filesFieldsLoader: false,
	filesSeparators: null,
	filesSeparatorsLoader: false,
	postMappingPresetsResponse: null,
	postMappingPresetsResponseLoader: false,
	mappingPresets: null,
	mappingPresetsLoader: false,
	startUploadingLoader: false,
	deleteMappingPresets: null,
	deleteMappingPresetsLoader: false,
};

// ==========================================:
const files = createSlice({
	name: '@@files',
	initialState,
	reducers: {
		postFilesRequest: (state: TFilesStore, action: PayloadAction<TPostFilesRequestPayload>) => {
			const { payload } = action;
			const postFilesRequestState = state;
			postFilesRequestState.postFilesResponseLoader = true;
		},
		postFilesSuccess: (state: TFilesStore, action: PayloadAction<TPostFilesResponsePayload>) => {
			const { payload } = action;
			const postFilesSuccessState = state;
			postFilesSuccessState.postFilesResponse = payload;
			postFilesSuccessState.postFilesResponseLoader = false;
		},
		deleteFilesRequest: (state: TFilesStore, action: PayloadAction<TDeleteFilesRequestPayload>) => {
			const { payload } = action;
			const deleteFilesRequestState = state;
			deleteFilesRequestState.deleteFilesResponseLoader = true;
		},

		filesExampleRequest: (
			state: TFilesStore,
			action: PayloadAction<TFilesExampleRequestPayload>,
		) => {
			const { payload } = action;
			const filesExampleRequestState = state;
			filesExampleRequestState.filesExampleLoader = true;
		},
		filesExampleSuccess: (
			state: TFilesStore,
			action: PayloadAction<TFilesExampleResponsePayload>,
		) => {
			const { payload } = action;
			const filesExampleSuccessState = state;
			filesExampleSuccessState.filesExample = payload;
			filesExampleSuccessState.filesExampleLoader = false;
		},

		filesFieldsRequest: (state: TFilesStore) => {
			const filesFieldsRequestState = state;
			filesFieldsRequestState.filesFieldsLoader = true;
		},
		filesFieldsSuccess: (
			state: TFilesStore,
			action: PayloadAction<TFilesFieldsResponsePayload>,
		) => {
			const { payload } = action;
			const filesFieldsSuccessState = state;
			filesFieldsSuccessState.filesFields = payload;
			filesFieldsSuccessState.filesFieldsLoader = false;
		},

		filesSeparatorsRequest: (state: TFilesStore) => {
			const filesSeparatorsRequestState = state;
			filesSeparatorsRequestState.filesSeparatorsLoader = true;
		},
		filesSeparatorsSuccess: (
			state: TFilesStore,
			action: PayloadAction<TFilesSeparatorsResponsePayload>,
		) => {
			const { payload } = action;
			const filesSeparatorsSuccessState = state;
			filesSeparatorsSuccessState.filesSeparators = payload;
			filesSeparatorsSuccessState.filesSeparatorsLoader = false;
		},

		postMappingPresetsRequest: (
			state: TFilesStore,
			action: PayloadAction<TPostMappingPresetsRequestPayload>,
		) => {
			const { payload } = action;
			const postFilesRequestState = state;
			postFilesRequestState.postFilesResponseLoader = true;
		},
		postMappingPresetsSuccess: (
			state: TFilesStore,
			action: PayloadAction<TPostMappingPresetsResponsePayload>,
		) => {
			const { payload } = action;
			const postMappingPresetsSuccessState = state;
			postMappingPresetsSuccessState.postMappingPresetsResponse = payload;
			postMappingPresetsSuccessState.postMappingPresetsResponseLoader = false;
		},

		mappingPresets: (state: TFilesStore, action: PayloadAction<TMappingPresetsRequestPayload>) => {
			const { payload } = action;
			const mappingPresetsState = state;
			mappingPresetsState.mappingPresetsLoader = true;
		},
		mappingPresetsSuccess: (
			state: TFilesStore,
			action: PayloadAction<TMappingPresetsResponsePayload>,
		) => {
			const { payload } = action;
			const mappingPresetsSuccessState = state;
			mappingPresetsSuccessState.mappingPresets = payload;
			mappingPresetsSuccessState.mappingPresetsLoader = false;
		},

		startUploading: (state: TFilesStore, action: PayloadAction<TStartUploadingRequestPayload>) => {
			const { payload } = action;
			const startUploadingState = state;
			startUploadingState.mappingPresetsLoader = true;
		},
		startUploadingSuccess: (state: TFilesStore) => {
			const startUploadingSuccessState = state;
			startUploadingSuccessState.startUploadingLoader = false;
		},

		deleteMappingPresets: (
			state: TFilesStore,
			action: PayloadAction<TDeleteMappingPresetsRequestPayload>,
		) => {
			const { payload } = action;
			const deleteMappingPresetsState = state;
			deleteMappingPresetsState.deleteMappingPresetsLoader = true;
		},
		deleteMappingPresetsSuccess: (
			state: TFilesStore,
			action: PayloadAction<TDeleteMappingPresetsResponsePayload>,
		) => {
			const { payload } = action;
			const deleteMappingPresetsSuccessState = state;
			deleteMappingPresetsSuccessState.deleteMappingPresets = payload;
			deleteMappingPresetsSuccessState.deleteMappingPresetsLoader = false;
		},

		assetsInitState: () => initialState,
	},
});

export default files.reducer;
export const {
	postFilesRequest,
	postFilesSuccess,
	deleteFilesRequest,
	filesExampleRequest,
	filesExampleSuccess,
	filesFieldsRequest,
	filesFieldsSuccess,
	filesSeparatorsRequest,
	filesSeparatorsSuccess,
	postMappingPresetsRequest,
	postMappingPresetsSuccess,
	mappingPresets,
	mappingPresetsSuccess,
	assetsInitState,
	startUploading,
	startUploadingSuccess,
	deleteMappingPresets,
	deleteMappingPresetsSuccess,
} = files.actions;
