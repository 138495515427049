import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TNewVATReportResponsePayload,
	TVatReportFileRequestPayload,
	TVatReportFileResponsePayload,
	TVatReportStatusRequestPayload,
	TVatReportStatusResponsePayload,
	TVATReportStore,
} from './types';
import { NEW_FILE_TYPE_ALL, NEW_FILE_TYPE_WITH_PAYMENT } from './constants';
// ==========================================:
export const initialState: TVATReportStore = {
	newFile: null,
	newFileType: null,
	newFileLoader: false,
	vatReportStatus: null,
	vatReportStatusLoader: false,
	vatReportFile: null,
	vatReportFileLoader: false,
};

// ==========================================:
const settings = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		newVATReportRequest: (state: TVATReportStore) => {
			const newFileRequestState = state;
			newFileRequestState.newFileLoader = true;
			newFileRequestState.newFileType = NEW_FILE_TYPE_ALL;
		},
		newVATReportWithPaymentRequest: (state: TVATReportStore) => {
			const newFileRequestState = state;
			newFileRequestState.newFileLoader = true;
			newFileRequestState.newFileType = NEW_FILE_TYPE_WITH_PAYMENT;
		},
		newVATReportSuccess: (
			state: TVATReportStore,
			action: PayloadAction<TNewVATReportResponsePayload>,
		) => {
			const { payload } = action;
			const vatReportSuccessState = state;
			vatReportSuccessState.newFile = payload;
			vatReportSuccessState.newFileLoader = false;
		},
		vatReportStatusRequest: (
			state: TVATReportStore,
			action: PayloadAction<TVatReportStatusRequestPayload>,
		) => {
			const vatReportRequestState = state;
			vatReportRequestState.vatReportStatusLoader = true;
		},
		vatReportStatusSuccess: (
			state: TVATReportStore,
			action: PayloadAction<TVatReportStatusResponsePayload>,
		) => {
			const { payload } = action;
			const vatReportSuccessState = state;
			vatReportSuccessState.vatReportStatus = payload;
			vatReportSuccessState.vatReportStatusLoader = false;
		},

		vatReportFileRequest: (
			state: TVATReportStore,
			action: PayloadAction<TVatReportFileRequestPayload>,
		) => {
			const vatReportRequestState = state;
			vatReportRequestState.vatReportStatusLoader = true;
		},

		vatReportFileSuccess: (
			state: TVATReportStore,
			action: PayloadAction<TVatReportFileResponsePayload>,
		) => {
			const { payload } = action;
			const vatReportSuccessState = state;
			vatReportSuccessState.vatReportFile = payload;
			vatReportSuccessState.vatReportFileLoader = false;
		},

		settingsInitState: () => initialState,
	},
});

export default settings.reducer;
export const {
	newVATReportRequest,
	newVATReportWithPaymentRequest,
	newVATReportSuccess,
	vatReportStatusRequest,
	vatReportStatusSuccess,
	vatReportFileRequest,
	vatReportFileSuccess,
	settingsInitState,
} = settings.actions;
