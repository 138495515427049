import { spawn } from 'redux-saga/effects';
import { authSaga } from './reducers/auth/saga';
import { clientsSaga } from './reducers/clients/saga';
import { filesSaga } from './reducers/files/saga';
import { ordersSaga } from './reducers/orders/saga';
import { settingsSaga } from './reducers/settings/saga';
import { vatReportSaga } from './reducers/vatreport/saga';

export function* rootSaga() {
	yield spawn(clientsSaga);
	yield spawn(filesSaga);
	yield spawn(ordersSaga);
	yield spawn(settingsSaga);
	yield spawn(authSaga);
	yield spawn(vatReportSaga);
}
