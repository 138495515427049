import { useSelector } from 'react-redux';
import { getAccessToken } from 'redux/reducers/auth/selectors';

export const Iframe = () => {
	const token = useSelector(getAccessToken);

	return (
		<div className="iframe-component" style={{ display: 'flex' }}>
			<div style={{ width: '300px', background: '#ddd' }} />
			<iframe
				style={{ height: 'calc(100vh - 85px)', width: '100vw', border: 'none' }}
				title="iframe component"
				// src="https://client-and-sales-page.easproject.com/"
				// src={`http://localhost:3000?token=${String(token)}`}
				// src="https://pay-dev.easproject.com/recurring/AF-00008985@898"
				src="http://localhost:3000/recurring/AF-00008985@898"
			>
				Ваш браузер не поддерживает плавающие фреймы!
			</iframe>
		</div>
	);
};
