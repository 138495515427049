import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TOrdersApi } from './types';

export const orders: TOrdersApi = {
	getOrders: (params) =>
		http
			.get(endpoint.orders.GET_ORDERS(params.clientId), { params })
			.then((response) => response.data),
	putOrderUpdate: (payload) =>
		http
			.put(endpoint.orders.PUT_ORDER_UPDATE(payload.orderId), payload.body)
			.then((response) => response.data),
	getOrdersStatuses: () =>
		http.get(endpoint.orders.GET_ORDERS_STATUSES).then((response) => response.data),
	getOrderBreakdowns: (params) =>
		http
			.get(endpoint.orders.GET_ORDER_BREAKDOWNS(params.orderId), { params })
			.then((response) => response.data),
	putOrderBreakdownUpdate: (payload) =>
		http
			.put(endpoint.orders.PUT_BREAKDOWN_UPDATE(payload.orderBreakdownId), payload.body)
			.then((response) => response.data),
};
