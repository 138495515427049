import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	TFilesExampleRequestPayload,
	TFilesExampleResponsePayload,
	TFilesFieldsResponsePayload,
	TFilesSeparatorsResponsePayload,
	TMappingPresetsRequestPayload,
	TMappingPresetsResponsePayload,
	TPostMappingPresetsRequestPayload,
	TPostMappingPresetsResponsePayload,
	TPostFilesRequestPayload,
	TPostFilesResponsePayload,
	TStartUploadingRequestPayload,
	TDeleteMappingPresetsRequestPayload,
	TDeleteMappingPresetsResponsePayload,
	TDeleteFilesRequestPayload,
} from './types';
import {
	filesExampleRequest,
	filesExampleSuccess,
	filesFieldsRequest,
	filesFieldsSuccess,
	filesSeparatorsRequest,
	filesSeparatorsSuccess,
	mappingPresets,
	mappingPresetsSuccess,
	postMappingPresetsRequest,
	postMappingPresetsSuccess,
	postFilesRequest,
	postFilesSuccess,
	startUploading,
	startUploadingSuccess,
	deleteMappingPresetsSuccess,
	deleteMappingPresets,
	deleteFilesRequest,
} from './reducer';
import { getClientFilesRequest } from '../clients/reducer';

// =============================================================:
function* postFilesWorker(action: PayloadAction<TPostFilesRequestPayload>) {
	const { payload } = action;

	try {
		const formData = new FormData();
		formData.append('File', payload.File);
		formData.append('ClientId', String(payload.ClientId));
		formData.append('SeparatorType', String(payload.SeparatorType));
		formData.append('IsHeaderRow', String(payload.IsHeaderRow));

		const response: TPostFilesResponsePayload = yield call(api.files.postFiles, formData);
		yield put(postFilesSuccess(response));
		notificationContainer('file uploaded successfully!', 'success');

		yield put(getClientFilesRequest({ clientId: payload.ClientId, pageSize: 10 }));
		if (response?.id)
			yield put(
				filesExampleRequest({ fileId: response?.id, separatorType: response?.separatorType }),
			);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

// =============================================================:
function* deleteFilesWorker(action: PayloadAction<TDeleteFilesRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.files.deleteFiles, payload);
		notificationContainer('file delete successfully!', 'success');

		yield put(getClientFilesRequest({ clientId: payload.clientId, pageSize: 10 }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
			yield put(getClientFilesRequest({ clientId: payload.clientId, pageSize: 10 }));
		}
	}
}

// =============================================================:
function* filesExampleWorker(action: PayloadAction<TFilesExampleRequestPayload>) {
	const { payload } = action;

	try {
		const response: TFilesExampleResponsePayload = yield call(api.files.fileExample, payload);
		yield put(filesExampleSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

// =============================================================:
function* filesFieldsWorker() {
	try {
		const response: TFilesFieldsResponsePayload = yield call(api.files.filesFields);
		yield put(filesFieldsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}
// =============================================================:
function* filesSeparatorsWorker() {
	try {
		const response: TFilesSeparatorsResponsePayload = yield call(api.files.filesSeparators);
		yield put(filesSeparatorsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

// =============================================================:
function* postMappingPresetsWorker(action: PayloadAction<TPostMappingPresetsRequestPayload>) {
	const { payload } = action;

	try {
		const response: TPostMappingPresetsResponsePayload = yield call(
			api.files.postMappingPresets,
			payload,
		);
		yield put(postMappingPresetsSuccess(response));
		notificationContainer('mapping preset uploaded successfully!', 'success');

		yield put(mappingPresets({ clientId: payload.clientId }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

// =============================================================:
function* mappingPresetsWorker(action: PayloadAction<TMappingPresetsRequestPayload>) {
	const { payload } = action;

	try {
		const response: TMappingPresetsResponsePayload = yield call(api.files.mappingPresets, payload);
		yield put(mappingPresetsSuccess(response));
		// yield put(getClientFilesRequest({ clientId: payload.clientId, pageSize: 10 }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

// =============================================================:
function* deleteMappingPresetsWorker(action: PayloadAction<TDeleteMappingPresetsRequestPayload>) {
	const { payload } = action;

	try {
		const response: TDeleteMappingPresetsResponsePayload = yield call(
			api.files.deleteMappingPresets,
			payload,
		);
		yield put(deleteMappingPresetsSuccess(response));
		notificationContainer('Mapping preset successfully removed!', 'success');

		yield put(mappingPresets({ clientId: payload.clientId }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

// =============================================================:
function* startUploadingWorker(action: PayloadAction<TStartUploadingRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.files.startUploading, payload);
		yield put(startUploadingSuccess());
		notificationContainer('mapping uploaded successfully!', 'success');
		yield put(getClientFilesRequest({ clientId: payload.clientId, pageSize: 10 }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

export function* filesSaga() {
	yield takeEvery(postFilesRequest.type, postFilesWorker);
	yield takeEvery(deleteFilesRequest.type, deleteFilesWorker);
	yield takeEvery(filesExampleRequest.type, filesExampleWorker);
	yield takeEvery(filesFieldsRequest.type, filesFieldsWorker);
	yield takeEvery(filesSeparatorsRequest.type, filesSeparatorsWorker);
	yield takeEvery(postMappingPresetsRequest.type, postMappingPresetsWorker);
	yield takeEvery(mappingPresets.type, mappingPresetsWorker);
	yield takeEvery(deleteMappingPresets.type, deleteMappingPresetsWorker);

	yield takeEvery(startUploading.type, startUploadingWorker);
}
