import { IAdminAuthUserResponseData, ILoginRequestPayload } from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TAuthApi } from './types';

// ==========================================:
export const auth: TAuthApi = {
	login: (payload) =>
		http
			.post<ILoginRequestPayload, { data: IAdminAuthUserResponseData }>(
				endpoint.auth.LOGIN,
				payload,
			)
			.then((response) => response.data),

	logout: () => http.post(endpoint.auth.LOGOUT),
};
