import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TClientFilesRequestPayload,
	TClientFilesResponsePayload,
	TClientRequestPayload,
	TClientResponsePayload,
	TClientsRequestPayload,
	TIntegrationTypesResponsePayload,
	TPostClientRequestPayload,
	TPostClientResponsePayload,
	TStartEasSendJobResponsePayload,
	TClientsStore,
	TClientsResponsePayload,
} from './types';
// ==========================================:
export const initialState: TClientsStore = {
	clients: null,
	clientsLoader: false,
	client: null,
	clientLoader: false,
	clientFiles: null,
	clientFilesLoader: false,
	integrationTypes: null,
	integrationTypesLoader: false,
	postClient: null,
	postClientLoader: false,
	startEasSendJob: null,
	startEasSendJobLoader: false,
};

// ==========================================:
const clients = createSlice({
	name: '@@clients',
	initialState,
	reducers: {
		getClientsRequest: (state: TClientsStore, action: PayloadAction<TClientsRequestPayload>) => {
			const clientsRequestState = state;
			clientsRequestState.clientsLoader = true;
		},
		getClientsSuccess: (state: TClientsStore, action: PayloadAction<TClientsResponsePayload>) => {
			const { payload } = action;
			const clientsSuccessState = state;
			clientsSuccessState.clients = payload;
			clientsSuccessState.clientsLoader = false;
		},
		postClientRequest: (state: TClientsStore, action: PayloadAction<TPostClientRequestPayload>) => {
			const postClientRequestState = state;
			postClientRequestState.postClientLoader = true;
		},
		postClientSuccess: (
			state: TClientsStore,
			action: PayloadAction<TPostClientResponsePayload>,
		) => {
			const { payload } = action;
			const postClientSuccessState = state;
			postClientSuccessState.postClient = payload;
			postClientSuccessState.postClientLoader = false;
		},

		getClientRequest: (state: TClientsStore, action: PayloadAction<TClientRequestPayload>) => {
			const clientRequestState = state;
			clientRequestState.clientLoader = true;
		},
		getClientSuccess: (state: TClientsStore, action: PayloadAction<TClientResponsePayload>) => {
			const { payload } = action;
			const clientSuccessState = state;
			clientSuccessState.client = payload;
			clientSuccessState.clientLoader = false;
		},
		deleteClientRequest: (state: TClientsStore, action: PayloadAction<TClientRequestPayload>) => {
			const { payload } = action;
			const clientRequestState = state;
			clientRequestState.clientLoader = true;
		},

		getClientFilesRequest: (
			state: TClientsStore,
			action: PayloadAction<TClientFilesRequestPayload>,
		) => {
			const clientFilesRequestState = state;
			clientFilesRequestState.clientFilesLoader = true;
		},
		getClientFilesSuccess: (
			state: TClientsStore,
			action: PayloadAction<TClientFilesResponsePayload>,
		) => {
			const { payload } = action;
			const clientFilesSuccessState = state;
			clientFilesSuccessState.clientFiles = payload;
			clientFilesSuccessState.clientFilesLoader = false;
		},

		getIntegrationTypesRequest: (state: TClientsStore) => {
			const integrationTypesRequestState = state;
			integrationTypesRequestState.integrationTypesLoader = true;
		},
		getIntegrationTypesSuccess: (
			state: TClientsStore,
			action: PayloadAction<TIntegrationTypesResponsePayload>,
		) => {
			const { payload } = action;
			const integrationTypesSuccessState = state;
			integrationTypesSuccessState.integrationTypes = payload;
			integrationTypesSuccessState.integrationTypesLoader = false;
		},

		startEasSendJobRequest: (state: TClientsStore) => {
			const startEasSendJobRequestState = state;
			startEasSendJobRequestState.startEasSendJobLoader = true;
		},
		startEasSendJobSuccess: (
			state: TClientsStore,
			action: PayloadAction<TStartEasSendJobResponsePayload>,
		) => {
			const { payload } = action;
			const startEasSendJobSuccessState = state;
			startEasSendJobSuccessState.startEasSendJob = payload;
			startEasSendJobSuccessState.startEasSendJobLoader = false;
		},

		assetsInitState: () => initialState,
	},
});

export default clients.reducer;
export const {
	getClientsRequest,
	getClientsSuccess,
	postClientRequest,
	postClientSuccess,
	getClientRequest,
	getClientSuccess,
	deleteClientRequest,
	getClientFilesRequest,
	getClientFilesSuccess,
	getIntegrationTypesRequest,
	getIntegrationTypesSuccess,
	startEasSendJobRequest,
	startEasSendJobSuccess,
	assetsInitState,
} = clients.actions;
