import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TFilesApi } from './types';

export const files: TFilesApi = {
	postFiles: (payload) =>
		http.post(endpoint.files.POST_FILES, payload).then((response) => response.data),
	deleteFiles: (payload) => http.delete(endpoint.files.DELETE_FILES(payload.fileId)),
	fileExample: (params) =>
		http
			.get(endpoint.files.FILE_EXAMPLE(params.fileId), { params })
			.then((response) => response.data),
	filesFields: () => http.get(endpoint.files.FILES_FIELDS).then((response) => response.data),
	filesSeparators: () =>
		http.get(endpoint.files.FILES_SEPARATORS).then((response) => response.data),
	postMappingPresets: (payload) =>
		http
			.post(endpoint.files.POST_MAPPING_PRESET(payload.clientId), payload.body)
			.then((response) => response.data),
	mappingPresets: (params) =>
		http.get(endpoint.files.MAPPING_PRESET(params.clientId)).then((response) => response.data),
	deleteMappingPresets: (payload) =>
		http
			.delete(endpoint.files.DELETE_MAPPING_PRESET(payload.clientId, payload.mappingId))
			.then((response) => response.data),
	startUploading: (payload) =>
		http.post(endpoint.files.STARTUP_LOADING(payload.fileId), payload.body),
};
