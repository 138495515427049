/* eslint-disable react-hooks/rules-of-hooks */
import { Container } from '@mui/material';
import { FC } from 'react';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { SubmitVATReportList } from './SubmitVATReportList';

export const SubmitVATReport: FC = () => {
	return (
		<div>
			<Container maxWidth="lg" sx={{ marginTop: '40px' }}>
				<SubmitVATReportList />
			</Container>
		</div>
	);
};
