import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TSettingsRequestPayload,
	TSettingsResponsePayload,
	TSettingsStore,
	TSettingsUpdateRequestPayload,
	TSettingsUpdateResponsePayload,
} from './types';
// ==========================================:
export const initialState: TSettingsStore = {
	settings: null,
	settingsLoader: false,
	settingsUpdate: null,
	settingsUpdateLoader: false,
};

// ==========================================:
const settings = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		getSettingsRequest: (state: TSettingsStore, action: PayloadAction<TSettingsRequestPayload>) => {
			const settingsRequestState = state;
			settingsRequestState.settingsLoader = true;
		},
		getSettingsSuccess: (
			state: TSettingsStore,
			action: PayloadAction<TSettingsResponsePayload>,
		) => {
			const { payload } = action;
			const settingsSuccessState = state;
			settingsSuccessState.settings = payload;
			settingsSuccessState.settingsLoader = false;
		},
		postSettingsUpdateRequest: (
			state: TSettingsStore,
			action: PayloadAction<TSettingsUpdateRequestPayload>,
		) => {
			const settingsUpdateRequestState = state;
			settingsUpdateRequestState.settingsUpdateLoader = true;
		},
		postSettingsUpdateSuccess: (
			state: TSettingsStore,
			action: PayloadAction<TSettingsUpdateResponsePayload>,
		) => {
			const { payload } = action;
			const settingsUpdateSuccessState = state;
			settingsUpdateSuccessState.settingsUpdate = payload;
			settingsUpdateSuccessState.settingsUpdateLoader = false;
		},

		settingsInitState: () => initialState,
	},
});

export default settings.reducer;
export const {
	getSettingsRequest,
	getSettingsSuccess,
	postSettingsUpdateRequest,
	postSettingsUpdateSuccess,
	settingsInitState,
} = settings.actions;
