import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TSettingsApi } from './types';

export const settings: TSettingsApi = {
	getSettings: (params) =>
		http
			.get(endpoint.settings.GET_SETTINGS(params.clientId), { params })
			.then((response) => response.data),
	postSettings: (payload) =>
		http
			.post(endpoint.settings.POST_SETTINGS(payload.clientId), payload.body)
			.then((response) => response.data),
};
