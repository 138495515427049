export const endpoint = {
	auth: {
		LOGIN: '/auth/token',
		LOGOUT: '/auth/logout',
	},
	clients: {
		GET_CLIENTS: '/client',
		ADD_CLIENT: '/client',
		GET_CLIENT: (clientId: number) => `/client/${clientId}`,
		DELETE_CLIENT: (clientId: number) => `/client/${clientId}`,
		GET_CLIENT_FILES: (clientId: number) => `/client/files/${clientId}`,
		GET_INTEGRATION_TYPES: '/client/integrationtypes',
		START_EAS_SEND_JOB: '/jobs/starteassendjob',
	},
	files: {
		POST_FILES: '/files',
		DELETE_FILES: (fileId: number) => `/files/${fileId}`,
		FILE_EXAMPLE: (fileId: number) => `/files/${fileId}/example`,
		FILES_FIELDS: '/files/fields',
		FILES_SEPARATORS: '/files/separators',
		POST_MAPPING_PRESET: (clientId: number) => `/client/${clientId}/mapping`,
		MAPPING_PRESET: (clientId: number) => `/client/${clientId}/mapping`,
		DELETE_MAPPING_PRESET: (clientId: number, mappingId: number) =>
			`/client/${clientId}/mapping/${mappingId}`,
		STARTUP_LOADING: (fileId: number) => `/files/${fileId}/startuploading`,
	},
	orders: {
		GET_ORDERS: (clientId: number) => `/client/orders/${clientId}`,
		PUT_ORDER_UPDATE: (orderId: string) => `/orders/${orderId}/order/update`,
		GET_ORDERS_STATUSES: `/orders/status/types`,
		GET_ORDER_BREAKDOWNS: (orderId: number) => `/orders/breakdowns/${orderId}`,
		PUT_BREAKDOWN_UPDATE: (orderBreakdownId: string) =>
			`/orders/${orderBreakdownId}/breakdown/update`,
	},
	settings: {
		GET_SETTINGS: (clientId: number) => `/client/${clientId}/settings`,
		POST_SETTINGS: (clientId: number) => `/client/${clientId}/settings`,
	},
	vatReport: {
		NEW_VAT_REPORT: '/vatreport/new',
		VAT_REPORT_STATUS: (taskId: string) => `/vatreport/${taskId}`,
		VAT_REPORT_FILE: (taskId: string) => `/vatreport/${taskId}/file`,
		NEW_VAT_REPORT_WITH_PAYMENT: '/vatreport/new-with-issues',
		// VAT_REPORT_STATUS_WITH_PAYMENT: (taskId: string) => `/vatreport/${taskId}`,
		// VAT_REPORT_FILE_WITH_PAYMENT: (taskId: string) => `/vatreport/${taskId}/file`,
	},
};
