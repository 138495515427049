/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClientRequest, getClientsRequest } from 'redux/reducers/clients/reducer';
import { getClients, getClientsIsLoad } from 'redux/reducers/clients/selectors';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import { TClientsRequestPayload } from 'redux/reducers/clients/types';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { integrationSystemTypeArray } from 'redux/reducers/clients/constants';
import { Loader } from 'ui/Loader';
import { DeleteDialog } from 'ui/DeleteDialog';

export const Home: FC = () => {
	const dispatch = useDispatch();
	const clients = useSelector(getClients);
	const clientsIsLoad = useSelector(getClientsIsLoad);

	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const { pageNumber } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);

	const handlePageChange = (event: unknown, page: number) => {
		setCurrentPage(page);
		const params: TClientsRequestPayload = {
			pageNumber: page || 1,
			pageSize: 10,
		};
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=${String(page)}`,
		});
		dispatch(getClientsRequest(params));
	};

	useEffect(() => {
		const params: TClientsRequestPayload = {
			pageNumber: +(pageNumber ?? 1),
			pageSize: 10,
		};
		dispatch(getClientsRequest(params));
	}, [dispatch]);

	const handlerClientClick = (id: number) => {
		navigate(`/client/${id}`);
	};

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [clientId, setClientId] = useState(0);

	const handleClickOpenDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleDeleteClient = () => {
		handleCloseDeleteDialog();
		dispatch(deleteClientRequest({ clientId }));
	};

	return (
		<Container maxWidth="lg">
			{!clients.clients?.items.length && !clientsIsLoad && (
				<div className="empty-data">
					<p className="empty__text">No clients found...</p>
				</div>
			)}
			{!!clientsIsLoad && <Loader />}
			{clients.clients?.items.length && !clientsIsLoad && (
				<>
					<Paper>
						<TableContainer sx={{ maxHeight: 800 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="center" colSpan={2}>
											id
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={3}>
											Name
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={3}>
											Integration System Type
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											Action
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{clients.clients?.items?.map((item) => (
										<StyledTableRow hover key={item.id} onClick={() => handlerClientClick(item.id)}>
											<StyledTableCell align="center" colSpan={2}>
												{item.id}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={3}>
												{item.name}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={3}>
												{integrationSystemTypeArray[item.integrationSystemTypeId]}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												<DeleteIcon
													sx={{ color: '#016193', cursor: 'pointer' }}
													onClick={(e) => {
														e.stopPropagation();
														handleClickOpenDeleteDialog();
														// handleDeleteClient(item.id);
														setClientId(item.id);
													}}
												/>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
					{clients.clients.items.length > 10 && (
						<Stack spacing={2} marginTop={2}>
							<Pagination
								count={Math.ceil((clients.clients?.itemsCount ?? 0) / 10)}
								page={currentPage}
								onChange={handlePageChange}
								// variant="outlined"
								shape="rounded"
								color="primary"
							/>
						</Stack>
					)}
					<DeleteDialog
						open={openDeleteDialog}
						setClose={handleCloseDeleteDialog}
						handleDelete={handleDeleteClient}
						text="Are you sure you want to delete this client?"
					/>
				</>
			)}
		</Container>
	);
};
