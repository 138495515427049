import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TClientsApi } from './types';

export const clients: TClientsApi = {
	getClients: (params) =>
		http.get(endpoint.clients.GET_CLIENTS, { params }).then((response) => response.data),
	getClient: (params) =>
		http.get(endpoint.clients.GET_CLIENT(params.clientId)).then((response) => response.data),
	postClient: (payload) =>
		http.post(endpoint.clients.ADD_CLIENT, payload).then((response) => response.data),
	deleteClient: (params) =>
		http.delete(endpoint.clients.DELETE_CLIENT(params.clientId)).then((response) => response.data),
	getClientFiles: (params) =>
		http
			.get(endpoint.clients.GET_CLIENT_FILES(params.clientId), { params })
			.then((response) => response.data),
	getIntegrationTypes: () =>
		http.get(endpoint.clients.GET_INTEGRATION_TYPES).then((response) => response.data),
	postStartEasSendJob: () =>
		http.post(endpoint.clients.START_EAS_SEND_JOB).then((response) => response.data),
};
