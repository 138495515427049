/* eslint-disable import/extensions */
import { ClientPage } from 'pages/ClientPage';
import { HomePage } from 'pages/HomePage';
import { IframePage } from 'pages/IframePage';
import { LoginPage } from 'pages/LoginPage';
import { OrderPage } from 'pages/OrderPage';
import { SubmitVATReportPage } from 'pages/SubmitVATReportPage';
import { VATPage } from 'pages/VATPage';
import { VATReportDownloadPage } from 'pages/VATReportDownloadPage';
import { VATReportPage } from 'pages/VATReportPage';
import { useRoutes } from 'react-router';

export const NavigateList = () => {
	const routes = useRoutes([
		{
			path: `/login`,
			element: <LoginPage />,
		},
		{
			path: `/`,
			element: <HomePage />,
		},
		{
			path: `/client/:clientId`,
			element: <ClientPage />,
		},
		{
			path: `/client/:clientId/order/:orderId`,
			element: <OrderPage />,
		},
		{
			path: `/vat-report`,
			element: <VATReportPage />,
		},
		{
			path: `/vat-report/:taskId`,
			element: <VATReportDownloadPage />,
		},

		{
			path: `/iframe`,
			element: <IframePage />,
		},
		{
			path: `/vat`,
			element: <VATPage />,
		},
		{
			path: `/submit-vat`,
			element: <SubmitVATReportPage />,
		},
	]);
	return routes;
};
