import { FC } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { NavigateList } from 'routes/routesList';

const App: FC = () => {
	return (
		<BrowserRouter>
			<NavigateList />
		</BrowserRouter>
	);
};

export default App;
