/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	assetsInitState,
	getOrderBreakdownsRequest,
	getOrderBreakdownsSuccess,
	getOrdersRequest,
	getOrdersStatusesRequest,
	getOrdersStatusesSuccess,
	getOrdersSuccess,
	putOrderBreakdownUpdateRequest,
	putOrderBreakdownUpdateSuccess,
	putOrderUpdateRequest,
	putOrderUpdateSuccess,
} from './reducer';
import {
	TOrderBreakdownsRequestPayload,
	TOrderBreakdownsResponsePayload,
	TOrderBreakdownUpdateRequestPayload,
	TOrderBreakdownUpdateResponsePayload,
	TOrdersRequestPayload,
	TOrdersResponsePayload,
	TOrdersStatusesResponsePayload,
	TOrderUpdateRequestPayload,
	TOrderUpdateResponsePayload,
} from './types';

function* getOrdersWorker(action: PayloadAction<TOrdersRequestPayload>) {
	const { payload } = action;
	try {
		const response: TOrdersResponsePayload = yield call(api.orders.getOrders, payload);
		yield put(getOrdersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* putOrderUpdateWorker(action: PayloadAction<TOrderUpdateRequestPayload>) {
	const { payload } = action;
	try {
		const response: TOrderUpdateResponsePayload = yield call(api.orders.putOrderUpdate, payload);
		yield put(
			getOrdersRequest({
				clientId: Number(payload.clientId),
				pageSize: 10,
				pageNumber: payload?.pageNumber ?? 1,
			}),
		);
		notificationContainer(String('Order updated'), 'success');
		yield put(putOrderUpdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getOrdersStatusesWorker() {
	try {
		const response: TOrdersStatusesResponsePayload = yield call(api.orders.getOrdersStatuses);
		yield put(getOrdersStatusesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getOrderBreakdownsWorker(action: PayloadAction<TOrderBreakdownsRequestPayload>) {
	const { payload } = action;
	try {
		const response: TOrderBreakdownsResponsePayload = yield call(
			api.orders.getOrderBreakdowns,
			payload,
		);
		yield put(getOrderBreakdownsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* putOrderBreakdownUpdateWorker(
	action: PayloadAction<TOrderBreakdownUpdateRequestPayload>,
) {
	const { payload } = action;
	try {
		const response: TOrderBreakdownUpdateResponsePayload = yield call(
			api.orders.putOrderBreakdownUpdate,
			payload,
		);
		yield put(
			getOrderBreakdownsRequest({
				orderId: Number(payload.orderId),
				pageSize: 10,
				pageNumber: payload?.pageNumber ?? 1,
			}),
		);
		notificationContainer(String('Order updated'), 'success');
		yield put(putOrderBreakdownUpdateSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

export function* ordersSaga() {
	yield takeEvery(getOrdersRequest.type, getOrdersWorker);
	yield takeEvery(putOrderUpdateRequest.type, putOrderUpdateWorker);
	yield takeEvery(getOrdersStatusesRequest.type, getOrdersStatusesWorker);
	yield takeEvery(getOrderBreakdownsRequest.type, getOrderBreakdownsWorker);
	yield takeEvery(putOrderBreakdownUpdateRequest.type, putOrderBreakdownUpdateWorker);
}
