import { Page } from 'layouts/Page';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { LoginPage } from 'pages/LoginPage';
import { SubmitVATReport } from 'components/SubmitVATReport';

export const SubmitVATReportPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="SubmitVATReportPage">
			<SubmitVATReport />
		</Page>
	) : (
		<LoginPage />
	);
};
