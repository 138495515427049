import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TOrderBreakdownsRequestPayload,
	TOrderBreakdownsResponsePayload,
	TOrderBreakdownUpdateRequestPayload,
	TOrderBreakdownUpdateResponsePayload,
	TOrdersRequestPayload,
	TOrdersResponsePayload,
	TOrdersStatusesResponsePayload,
	TOrdersStore,
	TOrderUpdateRequestPayload,
	TOrderUpdateResponsePayload,
} from './types';
// ==========================================:
export const initialState: TOrdersStore = {
	orders: null,
	ordersLoader: false,
	orderUpdate: null,
	orderUpdateLoader: false,
	ordersStatuses: null,
	ordersStatusesLoader: false,
	orderBreakdowns: null,
	orderBreakdownsLoader: false,
	orderBreakdownUpdate: null,
	orderBreakdownUpdateLoader: false,
};

// ==========================================:
const orders = createSlice({
	name: '@@orders',
	initialState,
	reducers: {
		getOrdersRequest: (state: TOrdersStore, action: PayloadAction<TOrdersRequestPayload>) => {
			const ordersRequestState = state;
			ordersRequestState.ordersLoader = true;
		},
		getOrdersSuccess: (state: TOrdersStore, action: PayloadAction<TOrdersResponsePayload>) => {
			const { payload } = action;
			const ordersSuccessState = state;
			ordersSuccessState.orders = payload;
			ordersSuccessState.ordersLoader = false;
		},
		putOrderUpdateRequest: (
			state: TOrdersStore,
			action: PayloadAction<TOrderUpdateRequestPayload>,
		) => {
			const orderUpdateRequestState = state;
			orderUpdateRequestState.orderUpdateLoader = true;
		},
		putOrderUpdateSuccess: (
			state: TOrdersStore,
			action: PayloadAction<TOrderUpdateResponsePayload>,
		) => {
			const { payload } = action;
			const orderUpdateSuccessState = state;
			orderUpdateSuccessState.orderUpdate = payload;
			orderUpdateSuccessState.orderUpdateLoader = false;
		},

		getOrdersStatusesRequest: (state: TOrdersStore) => {
			const ordersRequestStatusesState = state;
			ordersRequestStatusesState.ordersStatusesLoader = true;
		},
		getOrdersStatusesSuccess: (
			state: TOrdersStore,
			action: PayloadAction<TOrdersStatusesResponsePayload>,
		) => {
			const { payload } = action;
			const ordersStatusesSuccessState = state;
			ordersStatusesSuccessState.ordersStatuses = payload;
			ordersStatusesSuccessState.ordersStatusesLoader = false;
		},
		getOrderBreakdownsRequest: (
			state: TOrdersStore,
			action: PayloadAction<TOrderBreakdownsRequestPayload>,
		) => {
			const orderBreakdownsRequestState = state;
			orderBreakdownsRequestState.orderBreakdownsLoader = true;
		},
		getOrderBreakdownsSuccess: (
			state: TOrdersStore,
			action: PayloadAction<TOrderBreakdownsResponsePayload>,
		) => {
			const { payload } = action;
			const orderBreakdownsSuccessState = state;
			orderBreakdownsSuccessState.orderBreakdowns = payload;
			orderBreakdownsSuccessState.orderBreakdownsLoader = false;
		},
		putOrderBreakdownUpdateRequest: (
			state: TOrdersStore,
			action: PayloadAction<TOrderBreakdownUpdateRequestPayload>,
		) => {
			const orderBreakdownUpdateRequestState = state;
			orderBreakdownUpdateRequestState.orderBreakdownUpdateLoader = true;
		},
		putOrderBreakdownUpdateSuccess: (
			state: TOrdersStore,
			action: PayloadAction<TOrderBreakdownUpdateResponsePayload>,
		) => {
			const { payload } = action;
			const orderBreakdownUpdateSuccessState = state;
			orderBreakdownUpdateSuccessState.orderBreakdownUpdate = payload;
			orderBreakdownUpdateSuccessState.orderBreakdownUpdateLoader = false;
		},

		assetsInitState: () => initialState,
	},
});

export default orders.reducer;
export const {
	getOrdersRequest,
	getOrdersSuccess,
	putOrderUpdateRequest,
	putOrderUpdateSuccess,
	getOrdersStatusesRequest,
	getOrdersStatusesSuccess,
	getOrderBreakdownsRequest,
	getOrderBreakdownsSuccess,
	putOrderBreakdownUpdateRequest,
	putOrderBreakdownUpdateSuccess,
	assetsInitState,
} = orders.actions;
