import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	TNewVATReportData,
	TNewVATReportFileTypes,
	TVATReportStore,
	TVatReportStatusData,
} from './types';

// ==========================================:
const vatReportState = (state: TStoreState): TVATReportStore => state.vatreport;
export const getVatReport = createSelector(
	[vatReportState],
	(vatReport: TVATReportStore) => vatReport,
);

// ====================================================:
export const newVatReportIsLoad = createSelector(
	[getVatReport],
	(vatReport: TVATReportStore): boolean => vatReport.newFileLoader,
);

// ====================================================:
export const newVatReport = createSelector(
	[getVatReport],
	(vatReport: TVATReportStore): TNewVATReportData => vatReport.newFile,
);

// ====================================================:
export const vatReportStatusIsLoad = createSelector(
	[getVatReport],
	(vatReport: TVATReportStore): boolean => vatReport.vatReportStatusLoader,
);

// ====================================================:
export const vatReportStatus = createSelector(
	[getVatReport],
	(vatReport: TVATReportStore): TVatReportStatusData => vatReport.vatReportStatus,
);

// ====================================================:

export const newVatReportFileType = createSelector(
	[getVatReport],
	(vatReport: TVATReportStore): TNewVATReportFileTypes => vatReport.newFileType,
);
